/* .content-page {
  margin-left: 260px;
} */

/* .content-page {
  margin-left: 80px;
} */

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
}

.small-dropdown {
  width: 150px;
}
.modal-open .modal {
  background: rgba(0, 0, 0, 0.5);
}

.pointer {
  cursor: pointer;
}

.uploadbox {
  display: table-cell;
  vertical-align: middle;
  border: 2px dashed #e6e8ef;
  width: 30%;
  height: 100px;
  float: left;
  border-radius: 8px;
  position: relative;
  background: #ffffff;
  margin-bottom: 10px;
}

.custom-file-upload {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  line-height: 40px;
  text-align: center;
}

.custom-file-upload span {
  float: left;
  width: 100%;
  font-size: 13px;
  color: #e64141;
  line-height: 25px;
}

.custom-file-upload > .las {
  color: #e64141;
  font-size: 32px;
  float: left;
  width: 100%;
  margin-top: 22px;
}

/* .table-responsive {
  overflow-x: hidden;
} */


.no-wrap {
  white-space: nowrap;
}

/* Set a specific width for all td elements within tbody */
tbody td {
  width: 120px; /* Example fixed width, adjust as needed */
  max-width: 120px; /* Ensures the width doesn't exceed the set value */
  overflow: hidden; /* Prevents content from overflowing */
  text-overflow: ellipsis; /* Adds an ellipsis if the text overflows */
  white-space: nowrap; /* Keeps the content on a single line */
}



.table-borderless thead th {
  min-width: 80px;
}

.table-borderless thead .date-field {
  min-width: 200px;
}

